import {ref} from 'vue';
import api from '@/data/api';
import {Role} from '@/data/schemas';

const _roles = ref<Role[]>([]);

export default () => ({
    get() {
        if (!_roles.value.length) {
            api.getRoles().then(response => {
                _roles.value = response.data;
            });
        }

        return _roles;
    },
    async value() {
        if (!_roles.value.length) {
            _roles.value = (await api.getRoles()).data;
        }

        return _roles.value;
    }
});