import {ref} from 'vue';
import {ChapterModel} from '@/models/ChapterModel';

const chapter = ref<ChapterModel>();

export default () => ({
    getChapter() {
        return chapter;
    },
    open: (model: ChapterModel) => {
        chapter.value = model;
        document.body.classList.add('modal-open');
    },
    close: () => {
        chapter.value = undefined;
        document.body.classList.remove('modal-open');
    },
})