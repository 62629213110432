import {z} from 'zod';

export type AnswerOption = z.infer<typeof answerOptionSchema>;
const answerOptionSchema = z.object({
    answerValue: z.union([z.literal(0), z.literal(1), z.literal(2), z.literal(3)]),
    answerText: z.string()
});

export type Statement = z.infer<typeof statementSchema>;
const statementSchema = z.object({
    uuid: z.string(),
    question: z.string(),
    description: z.string(),
    weight: z.number(),
    options: z.array(answerOptionSchema)
});

export type ContentPage = z.infer<typeof contentPageSchema>;
export const contentPageSchema = z.object({
    content: z.string()
})

export type Tag = z.infer<typeof tagSchema>;
const tagSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string()
})

export type KnowledgeBaseRole = z.infer<typeof knowledgeBaseRoleSchema>;
const knowledgeBaseRoleSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string()
})

export type KnowledgeType = z.infer<typeof knowledgeTypeSchema>;
const knowledgeTypeSchema = z.array(z.object({
    id: z.number(),
    name: z.string(),
    description: z.string()
}))

export type KnowledgeRole = z.infer<typeof knowledgeRoleSchema>;
const knowledgeRoleSchema = z.object({
    id: z.number(),
    name: z.string()
})

export type LanguageSubject = z.infer<typeof languageSubjectSchema>;
export const languageSubjectSchema = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string()
})

export type Download = z.infer<typeof downloadSchema>;
export const downloadSchema = z.object({
    label: z.string(),
    linkType: z.union([z.literal("url"), z.literal("file")]),
    url: z.string()
})

export type ChapterResultIntroText = z.infer<typeof chapterResultIntroTextSchema>;
export const chapterResultIntroTextSchema = z.object({
    recommendedProductsInfo: z.string(),
    recommendedPractisesInfo: z.string(),
    actionInfo: z.string()
});

export type ChapterFiles = z.infer<typeof chapterFilesSchema>;
const chapterFilesSchema = z.object({
    exercises: z.string().nullable(),
    workQuestions: z.string().nullable()
});

export type Role = z.infer<typeof RoleSchema>;
export const RoleSchema = z.object({
    id: z.coerce.number(),
    name: z.coerce.string(),
    subtitle: z.coerce.string(),
    chapterIds: z.array(z.number()).optional().default([])
});

export type KnowledgeBaseOverviewData = z.infer<typeof knowledgeBaseOverviewDataSchema>;
const knowledgeBaseOverviewDataSchema = z.object({
    title: z.string(),
    text: z.string(),
    shareText: z.string(),
    knowledgeTypes: z.array(knowledgeTypeSchema),
    languageSubjects: z.array(languageSubjectSchema),
    knowledgeRoles: z.array(knowledgeRoleSchema)
})

export type CustomLabels = z.infer<typeof customLabelsSchema>;
const customLabelsSchema = z.object({
    resultParagraphText: z.string(),
    skippedChapterLabel: z.string(),
    printResultsButtonLabel: z.string(),
    wipeResultsButtonLabel: z.string()
})

export type KnowledgebaseItem = z.infer<typeof knowledgebaseItemSchema>;
const knowledgebaseItemSchema = z.object({
    id: z.number(),
    slug: z.string(),
    content: z.string(),
    knowledgeTypes: knowledgeTypeSchema,
    downloads: z.array(downloadSchema),
    image: z.string().nullable(),
    chapters: z.array(z.number()),
    title: z.string(),
    knowledgeRoles: z.array(knowledgeBaseRoleSchema),
    languageSubject: z.string()
})

export type RouterParams = z.infer<typeof routerParamsSchema>;
const routerParamsSchema = z.object({
    name: z.string().optional(),
    params: z.record(z.union([z.string(), z.number(), z.array(z.union([z.string(), z.number()]))])).optional()
})