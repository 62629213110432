
import {defineComponent, PropType, ref} from 'vue';
import Icon from '@/components/Icon.vue';
import {ChapterModel} from '@/models/ChapterModel';

export default defineComponent({
    name: 'ChapterDescriptionStand',
    components: {Icon},
    props: {
        chapter: {type: Object as PropType<ChapterModel>, required: true}
    },
    setup() {
        const collapseOpen = ref(true);
        const infoCollapse = () => {
            collapseOpen.value = !collapseOpen.value;
        }

        return {collapseOpen, infoCollapse};
    }
})
