
import {defineComponent} from 'vue';
import ResetStateButton from '@/components/ResetStateButton.vue';
import useModal from '@/composeables/useModal';

export default defineComponent({
    name: 'ClearCookieComponent',
    components: {ResetStateButton},
    setup() {
        return {
            close: useModal().close
        }
    }
})
