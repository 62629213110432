
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'ChapterInfoBlock',
    components: {Icon},
    props: {
        title: {type: String, required: true}
    },
    emits: ['infoBtn']
})
