
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import BaseModal from '@/components/BaseModal.vue';
import ResultSheet from '@/components/ResultSheet.vue';
import ResultSheetDownload from '@/components/ResultSheetDownload.vue';
import useChapterInfoModal from '@/composeables/useChapterInfoModal';
import useChapters from '@/composeables/useChapters';
import DownloadResultsButton from '@/components/input/DownloadResultsButton.vue';
import useLabels from '@/composeables/useLabels';
import SiteLogo from '@/components/SiteLogo.vue';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'ResultOverview',
    components: {LoadingSpinner, DownloadResultsButton, ResultSheetDownload, ResultSheet, BaseModal, Icon, SiteLogo},
    setup() {
        return {
            chapters: useChapters().get(),
            modalChapter: useChapterInfoModal(),
            labels: useLabels().get()
        };
    }
})
