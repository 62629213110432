import {ref} from 'vue';
import api from '@/data/api';
import {CustomLabels} from '@/data/schemas';

const _labels = ref<CustomLabels>();
let _promise: Promise<void>|undefined = undefined;

export default () => ({
    get() {
        if (!_labels.value && !_promise) {
            _promise = api.getCustomLabels().then((response) => {
                _labels.value = response.data;
                _promise = undefined;
            });
        }

        return _labels;
    }
});