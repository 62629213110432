import {Role, RoleSchema} from '@/data/schemas';
import {computed, ref} from 'vue';
import useSchoolScan from '@/composeables/useSchoolScan';
import useChapters from '@/composeables/useChapters';
import {z} from 'zod';
import {ChapterModel} from '@/models/ChapterModel';
import api from '@/data/api';

export const StoredDataSchema = z.object({
    answers: z.record(z.number()).optional().default({}),
    role: RoleSchema.optional(),
    startedScan: z.coerce.boolean().optional().default(false),
    skippedChapters: z.array(z.number()).optional().default([])
});

export type StoredData = z.infer<typeof StoredDataSchema>;

function initialStore() {
    const str = localStorage.getItem('store');
    return StoredDataSchema.parse(str ? JSON.parse(str) : {});
}

const _store = ref<StoredData>(initialStore());

export default () => ({
    getRole() {
        return computed(() => this.value('role'));
    },

    getAnswers() {
        return computed(() => this.value('answers'));
    },

    getStartedScan() {
        return computed(() => this.value('startedScan'));
    },

    getSkippedChapters() {
        return computed(() => this.value('skippedChapters'))
    },

    /** Helper method to (un)set an answer. */
    setAnswer(answer: {uuid: string; value: number|undefined}) {
        this.setAnswers([answer.uuid], answer.value);
    },

    /** Method for (un)setting multiple answers at once */
    setAnswers(answerIds: string[], value: number|undefined) {
        // Update answers
        answerIds.forEach((id) => {
            if (value === undefined) {
                delete _store.value.answers[id];
            } else {
                _store.value.answers[id] = value;
            }
        });

        // Update your localstorage
        localStorage.setItem('store', JSON.stringify(_store.value));

        // Potentially update your database entry
        const schoolScan = useSchoolScan().getEntry().value;
        const role = this.getRole().value;

        if (schoolScan && role) {
            const chapters = useChapters().get().value;

            if (chapters) {
                const answers = this.getAnswers().value;
                const answersToSubmit: Record<string, number> = {};
                let isScanComplete = true;

                // Check if every chapter has been either completed or skipped
                for (const chapter of chapters) {
                    if (chapter.isComplete(answers)) {
                        for (const uid of Object.keys(chapter.statements)) {
                            answersToSubmit[uid] = answers[uid];
                        }
                    } else if (!_store.value.skippedChapters.includes(chapter.id)) {
                        isScanComplete = false;
                    }
                }

                // All chapters are completed or skipped!
                if (isScanComplete) {
                    const data = new FormData();
                    data.set('uuid', schoolScan.uuid);
                    data.set('code', schoolScan.scan.code);
                    data.set('answers', JSON.stringify(answersToSubmit));
                    data.set('roleId', String(role.id));

                    api.post('school-scan/entries', data).then((result) => {
                        console.log(result);
                    });
                }
            } else {
                console.error('Cannot save answers because chapters could not be fetched.')
            }
        }
    },

    skipChapter(chapter: ChapterModel) {
        _store.value.skippedChapters.push(chapter.id);
        this.setAnswers(Object.keys(chapter.statements), undefined);

        localStorage.setItem('store', JSON.stringify(_store.value));
    },

    async setRole(role: Role|undefined) {
        if (role?.id !== _store.value.role?.id) {
            _store.value.role = role;
            localStorage.setItem('store', JSON.stringify(_store.value));
        }

        return useChapters().fetch();
    },

    setStartedScan(started: boolean) {
        if (started !== _store.value.startedScan) {
            _store.value.startedScan = started;
            localStorage.setItem('store', JSON.stringify(_store.value));
        }
    },

    value<T extends keyof StoredData>(key: T) {
        return _store.value?.[key] as StoredData[T];
    },

    clear() {
        localStorage.removeItem('store');
        _store.value = StoredDataSchema.parse({});
    }
});