
import {defineComponent, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import ResultSheetDownload from '@/components/ResultSheetDownload.vue';
import useChapterInfoModal from '@/composeables/useChapterInfoModal';
import BaseModal from '@/components/BaseModal.vue';
import useLabels from '@/composeables/useLabels';
import BundledResultsSheet from '@/components/BundledResultsSheet.vue';
import DownloadResultsButton from '@/components/input/DownloadResultsButton.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import api from '@/data/api';
import {BundledSchoolResults, BundledSchoolResultsSchema} from '@/data/BundledSchoolResults';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'BundledResultsOverview',
    components: {LoadingSpinner, DownloadResultsButton, ResultSheetDownload, BaseModal, BundledResultsSheet, SiteLogo},
    setup() {
        const results = ref<BundledSchoolResults>();
        const pass = String(useRoute().query.pass);
        const router = useRouter();

        api.get('school-scan/entries', {pass}).then((result) => {
            if (result.ok) {
                results.value = BundledSchoolResultsSchema.parse(result.data);
            } else {
                window.alert('Incorrect passcode!');
                router.push({name: 'Home'});
            }
        });

        return {
            results,
            modalChapter: useChapterInfoModal(),
            labels: useLabels().get()
        };
    }
});
