import {computed, ref} from 'vue';

const modalComponent = ref('');
const modalTitle = ref('');

export default () => ({
    component: computed(() => modalComponent.value),
    isOpen: computed(() => Boolean(modalComponent.value)),
    title: computed(() => modalTitle.value),
    open: (componentToOpen: string, title = '') => {
        modalComponent.value = componentToOpen;
        modalTitle.value = title;
    },
    close: () => {
        modalComponent.value = '';
        modalTitle.value = '';
    },
    setTitle: (title: string) => {
        modalTitle.value = title;
    }
})