import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router';
import {trackRouter} from 'vue-gtag-next';
import ContentPage from '@/views/ContentPage.vue';
import ChapterQuestionList from '@/views/ChapterQuestionList.vue';
import ResultOverview from '@/views/ResultOverview.vue';
import ResultChapter from '@/views/ResultChapter.vue';
import KnowledgebaseOverview from '@/views/KnowledgebaseOverview.vue';
import KnowledgebaseDetail from '@/views/KnowledgebaseDetail.vue';
import SelectRole from '@/views/SelectRole.vue';
import Introduction from '@/views/Introduction.vue';
import BundledResultsOverview from '@/views/BundledResultsOverview.vue';

declare global {
    interface Window {
        debugMode?: boolean;
    }
}

declare module 'vue-router' {
    interface RouteMeta {
        slug?: typeof _ContentPages[number];
    }
}

export const _Routes = [
    'Home',
    // 'ContentPageCriteria',
    'ContentPageAbout',
    'Introduction',
    'ContentPageColofon',
    'ChapterQuestionList',
    'ResultOverview',
    'ResultChapterAdvice',
    'SelectRole',
    'KnowledgeBaseOverview',
    'KnowledgebaseDetail',
    'StartScan',
    'BundledResultsOverview'
] as const;

export enum Routes {
    Home = 'Home',
    // ContentPageCriteria = 'ContentPageCriteria',
    ContentPageAbout = 'ContentPageAbout',
    ContentPageIntro = 'Introduction',
    ContentPageColofon = 'ContentPageColofon',
    ChapterQuestionList = 'ChapterQuestionList',
    ResultOverview = 'ResultOverview',
    ResultChapterAdvice = 'ResultChapterAdvice',
    SelectRole = 'SelectRole',
    KnowledgeBaseOverview = 'KnowledgeBaseOverview',
    KnowledgebaseDetail = 'KnowledgebaseDetail',
    StartScan = 'StartScan',
}

export const _ContentPages = [
    'home',
    'over-deze-tool',
    'colofon',
    // 'criteria',
    'introductie'
] as const;

type RouteRecordRawWithName = RouteRecordRaw & {
    name: typeof _Routes[number];
}

const _routes: RouteRecordRawWithName[] = [
    {path: '/', name: 'Home', component: ContentPage, meta: {slug: 'home'}},
    {path: '/introductie', name: 'Introduction', component: Introduction, meta: {parent: 'StartScan', slug: 'introductie'}},
    {path: '/colofon', name: 'ContentPageColofon', component: ContentPage, meta: {slug: 'colofon'}},
    {path: '/over-deze-scan', name: 'ContentPageAbout', component: ContentPage, meta: {slug: 'over-deze-tool'}},
    // {path: '/criteria', name: 'ContentPageCriteria', component: ContentPage, meta: {slug: 'criteria'}},

    {path: '/scan', name: 'StartScan', redirect: () => {
        return {name: 'Introduction'};
    }},

    {path: '/scan/hoofdstuk/:id', name: 'ChapterQuestionList', component: ChapterQuestionList, props: true},
    {path: '/scan/select-role', name: 'SelectRole', component: SelectRole, meta: {parent: 'StartScan'}},

    {path: '/school-scan-resultaten', name: 'BundledResultsOverview', component: BundledResultsOverview},
    {path: '/resultaten', name: 'ResultOverview', component: ResultOverview},
    {path: '/resultaten/:id', name: 'ResultChapterAdvice', component: ResultChapter, props: true},

    {path: '/kennisbank', name: 'KnowledgeBaseOverview', component: KnowledgebaseOverview},
    {path: '/kennisbank/:slug', name: 'KnowledgebaseDetail', component: KnowledgebaseDetail, props: true},
];

const router = createRouter({history: createWebHashHistory(), routes: _routes});

trackRouter(router);

export default router;