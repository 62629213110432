
import {defineComponent} from 'vue';
import useModal from '@/composeables/useModal';
import StartSchoolScan from '@/components/modal/StartSchoolScan.vue';
import CreateSchoolScan from '@/components/modal/CreateSchoolScan.vue';
import ClearCookieComponent from '@/components/ClearCookieComponent.vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'GlobalModal',
    components: {Icon, ClearCookieComponent, CreateSchoolScan, StartSchoolScan},
    setup() {
        return {
            modalComponent: useModal().component,
            modalTitle: useModal().title,
            closeModal: useModal().close
        };
    }
})
