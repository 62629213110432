import {computed, ref} from 'vue';
import {ChapterModel} from '@/models/ChapterModel';
import api from '@/data/api';
import {ChapterCollection} from '@/data/classes/ChapterCollection';
import useStorage from '@/composeables/useStorage';

const _chapters = ref<ChapterModel[]>();
let _roleId = 0;
let _promises: {[key: number]: Promise<ChapterModel[]>} = {};

export default () => ({
    async fetch() {
        const roleId = useStorage().getRole().value?.id;

        if (roleId === undefined) {
            return [];
        }

        if (!_promises[roleId]) {
            if (roleId === _roleId && _chapters.value) {
                return Promise.resolve(_chapters.value);
            }

            _promises[roleId] = api.getChapters(roleId).then((chapters) => {
                _roleId = roleId;
                _chapters.value = chapters;

                return chapters;
            });
        }

        return _promises[roleId];
    },
    async value() {
        return this.fetch();
    },
    get() {
        this.fetch();
        return _chapters;
    },
    clear() {
        _roleId = 0;
        _chapters.value = [];
        _promises = {};
    },
    find(id: number) {
        return computed(() => _chapters.value?.find((chap) => chap.id === id));
    },
    collect(chapterId: number) {
        this.fetch();
        return computed(() => _chapters.value ? new ChapterCollection(_chapters.value, chapterId) : undefined);
    }
});