import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.chapter.description)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: "d-inline ml-3 cursor-pointer d-print-none",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChapterModal(_ctx.chapter)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : _createCommentVNode("", true)
}