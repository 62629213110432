
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'ChapterNavItem',
    props: {
        selected: {type: Boolean, required: true},
        url: {type: String, required: true}
    },
    emits: ['btnClick'],
})
