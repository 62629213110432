
import {defineComponent, PropType} from 'vue';
import {RouteLocationRaw} from 'vue-router';

export default defineComponent({
    name: 'RouterLinkButton',
    props: {
        to: {type: Object as PropType<RouteLocationRaw>, required: true},
        disabled: {type: Boolean, default: false}
    },
})
