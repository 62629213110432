import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "result-chapter-section section-container" }
const _hoisted_2 = { class: "result-chapter-navigation-wrapper mb-2" }
const _hoisted_3 = { class: "return-button pr-0" }
const _hoisted_4 = { class: "d-flex justify-content-between" }
const _hoisted_5 = { class: "result-chapter-navigation-wrapper__return-button" }
const _hoisted_6 = { class: "chevron-circle chevron-circle--left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ChapterResultNav = _resolveComponent("ChapterResultNav")!
  const _component_ChapterKnowledgebaseList = _resolveComponent("ChapterKnowledgebaseList")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_RouterLink, {
              to: {name: 'ResultOverview'},
              class: "btn btn-link btn-link--left"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, [
                  _createVNode(_component_Icon, { icon: "chevronCircleLeft" })
                ]),
                _createTextVNode(" Terug naar overzicht ")
              ]),
              _: 1
            })
          ]),
          _createVNode(_component_ChapterResultNav, {
            class: "d-block d-lg-none",
            "current-id": parseInt(_ctx.id)
          }, null, 8, ["current-id"])
        ])
      ])
    ]),
    (_ctx.chapter)
      ? (_openBlock(), _createBlock(_component_ChapterKnowledgebaseList, {
          key: 0,
          chapter: _ctx.chapter
        }, null, 8, ["chapter"]))
      : _createCommentVNode("", true)
  ]))
}