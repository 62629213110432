import {ChapterModel} from '@/models/ChapterModel';
import {Routes} from '@/router';
import {RouterParams} from '@/data/schemas';
import useStorage from '@/composeables/useStorage';

export class ChapterCollection {
    private readonly chapters: ChapterModel[];
    private pointer = -1;

    constructor(chapters: ChapterModel[], chapterId?: number) {
        this.chapters = chapters;

        if (chapterId) {
            this.chapters.forEach((chapter, i) => {
                if (chapter.id === chapterId) {
                    this.pointer = i;
                }
            });

            if (this.pointer < 0) {
                console.error('Pointer error', this.pointer);
                console.error(this.chapters);
                throw `ID ${chapterId} not found in collection.`;
            }
        }
    }

    public isComplete() {
        for (const chapter of this.chapters) {
            if (chapter.isComplete(useStorage().getAnswers().value)) {
                return false;
            }
        }

        return Boolean(this.chapters);
    }

    public all() {
        return this.chapters;
    }

    public getCurrent() {
        return this.chapters[this.pointer];
    }

    public getPrevRoute(): RouterParams {
        if (this.pointer >= 1) {
            return this.chapters[this.pointer - 1].getRoute(false);
        }

        return {name: Routes.SelectRole};
    }

    public getNextRoute(): RouterParams {
        if (this.pointer < this.chapters.length - 1) {
            return this.chapters[this.pointer + 1].getRoute(false);
        }

        return {name: Routes.ResultOverview};
    }
}