import {z} from 'zod';
import {jsonParse} from '../helpers/objectHelper';
import {v4 as uuidv4} from 'uuid';
import {ref} from 'vue';
import api from '@/data/api';
import useModal from '@/composeables/useModal';
import useStorage from '@/composeables/useStorage';
import {Router} from 'vue-router';

const SchoolScanSchema = z.object({
    schoolName: z.string(),
    code: z.string()
});

export const SchoolScanEntrySchema = z.object({
    entryId: z.number().optional(),
    uuid: z.string(),
    scan: SchoolScanSchema
});

export type SchoolScanEntry = z.infer<typeof SchoolScanEntrySchema>;

const KEY = 'tos_school_entry';
const _entry = ref<SchoolScanEntry>();
let _initialised = false;

export default () => ({
    setEntry(entry: SchoolScanEntry) {
        localStorage.setItem(KEY, JSON.stringify(entry));
        _entry.value = entry;
    },
    getEntry() {
        if (!_initialised && !_entry.value) {
            // TODO: Fetch based on UUID!
            const item = jsonParse(localStorage.getItem(KEY) ?? '', SchoolScanEntrySchema);

            if (item) {
                this.setEntry(item);
            }

            _initialised = true;
        }

        return _entry;
    },
    clear() {
        localStorage.removeItem(KEY);
        _entry.value = undefined;
        _initialised = false;
    },
    async startSchoolScan(code: string, router: Router) {
        if (useStorage().getStartedScan().value) {
            window.alert('U bent al met een scan bezig.');
            await router.push({name: 'Introduction'});
            throw 'You are aleady doing a scan.';
        }

        const result = await api.get('school-scan/scan', {code});
        if (result.ok) {
            this.clear();
            useStorage().clear();

            const schoolScanEntry = Object.assign(result.data as {}, {uuid: uuidv4()});

            this.setEntry(SchoolScanEntrySchema.parse(schoolScanEntry));

            useModal().close();
            useStorage().setStartedScan(true);

            await router.push({name: 'SelectRole'});
        } else {
            console.error(result);
        }

        return result;
    }
});