
import {defineComponent, PropType} from 'vue';
import {ChapterModel} from '@/models/ChapterModel';
import useChapterInfoModal from '@/composeables/useChapterInfoModal';

export default defineComponent({
    name: 'ChapterModalButton',
    props: {
        chapter: {type: Object as PropType<ChapterModel>, required: true}
    },
    setup() {
        return {
            toggleChapterModal(chapter?: ChapterModel) {
                if (chapter) {
                    useChapterInfoModal().open(chapter);
                } else {
                    useChapterInfoModal().close();
                }
            },
        };
    }
});
