
import {defineComponent} from 'vue';
import ChapterDescriptionStand from '@/components/ChapterDescriptionStand.vue';
import ChapterQuestion from '@/components/form/ChapterQuestion.vue';
import Icon from '@/components/Icon.vue';
import {useRouter} from 'vue-router';
import RouterLinkButton from '@/components/RouterLinkButton.vue';
import useStorage from '@/composeables/useStorage';
import useChapters from '@/composeables/useChapters';

export default defineComponent({
    name: 'ChapterQuestionList',
    components: {RouterLinkButton, ChapterQuestion, ChapterDescriptionStand, Icon},
    props: {
        id: {type: String, required: true}
    },
    setup(props) {
        const router = useRouter();
        const chapters = useChapters().collect(parseInt(props.id));

        const skipChapter = () => {
            const chapterCollection = chapters.value;
            if (chapterCollection) {
                useStorage().skipChapter(chapterCollection.getCurrent());
                router.push(chapterCollection.getNextRoute());
            }
        }

        return {
            answers: useStorage().getAnswers(),
            chapters,
            skipChapter
        };
    }
})
