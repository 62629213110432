import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-primary",
    disabled: _ctx.isBusy,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.download && _ctx.download(...args)))
  }, " Download PDF ", 8, _hoisted_1))
}