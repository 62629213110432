
import {defineComponent} from 'vue';
import useModal from '@/composeables/useModal';

export default defineComponent({
    name: 'ClearCookieButton',
    setup() {
        return {
            openClearCookieModal() {
                useModal().open('ClearCookieComponent', 'Nieuwe sessie starten');
            }
        };
    }
});
