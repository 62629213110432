import {z, ZodType} from 'zod';

export type JsonValue = string | number | boolean | JsonValue[] | {[key: string]: JsonValue|null};

/** Parse a string to JSON and parse the result with Zod.<br>Will return <b>undefined</b> if either parse action fails. */
export function jsonParse<Z extends ZodType>(text: string, type: Z): z.infer<typeof type>|undefined {
    try {
        return type.parse(text ? JSON.parse(text) : undefined);
    } catch (e) {
        return undefined;
    }
}

/** Check if something is a plain Javascript object */
export function isObject(obj: unknown): obj is Record<string, unknown> {
    return (typeof obj === 'object' && !Array.isArray(obj) && obj !== null);
}

/** Map a plain Javascript object. */
export function mapObject<V, R>(obj: Record<string, V>, fn: (v: V, k: string, i: number) => R) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}