import {ChapterFiles, Statement} from '@/data/schemas';
import {Rating} from '@/data/enums';
import {Routes} from '@/router';

export class ChapterModel {
    public readonly id: number;
    public readonly name: string;
    public readonly resultText: {[key: number]: string};
    public readonly statements: {[key: string]: Statement};
    public readonly menuColor: string;
    public readonly moreInfoLabel: string;
    public readonly description: string;
    public readonly files: ChapterFiles;
    public readonly introduction: string;
    public readonly chapterIndex: number;
    public readonly participantRoleIds: number[];

    constructor(data: any, chapterIndex: number) {
        this.id = Number(data.id);
        this.name = String(data.name);
        this.resultText = data.resultText;
        this.statements = data.statements;
        this.menuColor = String(data.menuColor);
        this.moreInfoLabel = String(data.moreInfoLabel);
        this.description = data.description;
        this.files = data.files;
        this.introduction = String(data.introduction);
        this.participantRoleIds = data.participantRoleIds;
        this.chapterIndex = chapterIndex;
    }

    public isComplete(answers: {[p: string]: number}) {
        let answeredQuestions = 0;

        // Track how many questions have been answered
        for (const uuid in this.statements) {
            const answer = (uuid in answers) ? answers[uuid] : undefined;

            if (answer !== undefined) {
                answeredQuestions += 1;
            } else if (answeredQuestions > 0) {
                return false;
            }
        }

        return answeredQuestions === Object.keys(this.statements).length;
    }

    /** Get the rating based on given answers for this competence */
    public getRating(givenAnswers: {[p: string]: number}) {
        const score = this.getScore(givenAnswers);
        const brackets = this.getMaxScore() / 3;

        if (score >= brackets * 2) {
            return Rating.Positive;
        }

        if (score >= brackets) {
            return Rating.Neutral;
        }

        return Rating.Negative;
    }

    /** Calculate the highest possible score for this competence */
    public getMaxScore() {
        let maxScore = 0;

        for (const uuid in this.statements) {
            const statement = this.statements[uuid];
            let highestValue = 0;

            for (const option of statement.options) {
                if (option.answerValue > highestValue) {
                    highestValue = option.answerValue;
                }
            }

            maxScore += highestValue * statement.weight;
        }

        return maxScore;
    }

    /** Get the total rating based on given answers for this competence */
    public getScore(answers: {[uuid: string]: number}) {
        let totalScore = 0;

        for (const uuid in this.statements) {
            const statement = this.statements[uuid];
            const answerKey = answers[uuid];

            // While it *shouldn't* happen, it is possible for a question to have it's options changed after it's been filled out.
            if (!statement.options[answerKey]) {
                console.warn(`Question ${uuid} was answered with ${answerKey} which is not valid option value!`);
                continue;
            }

            totalScore += statement.options[answerKey].answerValue * statement.weight;
        }

        return totalScore;
    }

    /** Retrieve the appropriate result text based on the user's score */
    public getResultText(givenAnswers: {[p: string]: number}) {
        return this.resultText[this.getRating(givenAnswers)];
    }

    public getRoute(resultPage: boolean) {
        return {
            name: (resultPage) ? Routes.ResultChapterAdvice : Routes.ChapterQuestionList,
            params: {id: this.id.toString()}
        }
    }

    public getStatement(key: string) {

    }
}