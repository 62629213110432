
import {defineComponent, PropType, ref} from 'vue';
//@ts-ignore
import * as html2pdf from 'html2pdf.js';

interface PdfInterface {
    internal: {
        getNumberOfPages(): number
        pageSize: {
            getWidth(): number
            getHeight(): number
        }
    }
    setPage(arg: number): PdfInterface
    setFontSize(arg: number): PdfInterface
    setTextColor(arg: number): PdfInterface
    text(arg1: string, arg2: number, arg3: number): PdfInterface
}

export default defineComponent({
    name: 'ResultSheetDownload',
    props: {
      resultType: {type: String as PropType<'individual'|'bundled'>, default: 'individual'}
    },
    setup() {
        const isBusy = ref(false);

        return {
            isBusy,
            download() {
                if (!isBusy.value) {
                    const source = document.querySelector('[data-result-content]')?.cloneNode(true) as HTMLElement|undefined;

                    if (source) {
                        isBusy.value = true;
                        source.classList.add('print');

                        html2pdf().from(source).set({
                            margin: [1, 1, 1.5, 1],
                            filename: `Taal Onderwijsscan resultaten ${new Date().getTime()}`,
                            pagebreak: {mode: ['css', 'legacy']},
                            jsPDF: {
                                unit: 'cm',
                                format: 'a4',
                                orientation: 'p',
                            }

                        }).toPdf().get('pdf').then((pdf: PdfInterface) => {
                            const totalPages = pdf.internal.getNumberOfPages();
                            for (let i = 1; i <= totalPages; i++) {
                                pdf.setPage(i);
                                pdf.setFontSize(10);
                                pdf.setTextColor(150);
                                pdf.text(`Pagina ${i} van ${totalPages}`, pdf.internal.pageSize.getWidth() / 2, pdf.internal.pageSize.getHeight() * 0.99);
                            }

                            isBusy.value = false;
                            source.classList.remove('print');
                        }).save();
                    }
                }
            }
        }
    }
})
