
import '@/assets/scss/app.scss';
import '@/assets/scss/print.scss';
import {defineComponent} from 'vue';
import HeaderMenu from '@/views/layout/HeaderMenu.vue';
import GlobalModal from '@/standalone/GlobalModal.vue';
import useModal from '@/composeables/useModal';

export default defineComponent({
    name: 'ToolLoopbaanCompetenties',
    components: {GlobalModal, HeaderMenu},
    setup() {
        return {
            inert: useModal().isOpen
        }
    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
        },
    },
})
