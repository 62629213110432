import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-wrapper" }
const _hoisted_2 = ["inert"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderMenu = _resolveComponent("HeaderMenu")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_GlobalModal = _resolveComponent("GlobalModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderMenu),
    _createElementVNode("main", null, [
      _createElementVNode("div", {
        class: "main__content",
        inert: _ctx.inert
      }, [
        (_openBlock(), _createBlock(_component_RouterView, {
          key: _ctx.$route.fullPath
        }))
      ], 8, _hoisted_2)
    ]),
    _createVNode(_component_GlobalModal)
  ]))
}