import {SchoolScanResultEntry} from '@/data/SchoolScanResultEntry';
import {ChapterModel} from '@/models/ChapterModel';
import {Role} from '@/data/schemas';

export type BundledResults = {
    content: Record<number, {score: number, entries: number, skips: number}>;
    roleId: number;
}[];

function getChapterScoreForEntry(answers: Record<string, number>, chapter: ChapterModel) {
    let score = 0;

    for (const [uid, statement] of Object.entries(chapter.statements)) {
        if (uid in answers) {
            const chosenOptionIndex = answers[uid];

            score += statement.options[chosenOptionIndex].answerValue * statement.weight;
        } else {
            return undefined;
        }
    }

    return score;
}

export function calculateAverageScore(entries: SchoolScanResultEntry[], roles: Role[], chapters: ChapterModel[]) {
    const data: BundledResults = [];

    for (const role of roles) {
        const roleIndex = data.length;
        data[roleIndex] = {roleId: role.id, content: {}};

        for (const chapter of chapters) {
            if (!chapter.participantRoleIds.includes(role.id)) {
                continue;
            }

            data[roleIndex].content[chapter.id] = {score: 0, skips: 0, entries: 0};

            for (const entry of Object.values(entries)) {
                if(entry.roleId !== role.id) {
                    continue;
                }

                const score = getChapterScoreForEntry(entry.answers, chapter);

                if (score === undefined) {
                    data[roleIndex].content[chapter.id].skips += 1;
                } else {
                    data[roleIndex].content[chapter.id].entries += 1;
                    data[roleIndex].content[chapter.id].score += score;
                }
            }

            if (data[roleIndex].content[chapter.id].entries) {
                data[roleIndex].content[chapter.id].score /= data[roleIndex].content[chapter.id].entries;
            }
            
        }
    }

    return data;
}

export function countGivenAnswers(entries: SchoolScanResultEntry[]) {
    const answerCount: Record<string, number[]> = {};

    for (const entry of entries) {
        for (const [uid, index] of Object.entries(entry.answers)) {
            if (!(uid in answerCount)) {
                answerCount[uid] = [];
            }

            if (index in answerCount[uid]) {
                answerCount[uid][index]++;
            } else {
                answerCount[uid][index] = 1;
            }
        }
    }

    return answerCount;
}