
import {defineComponent, PropType} from 'vue';

interface IconData {path: string; x: number; y: number}

const icons: {[p: string]: IconData} = Object.freeze({
    home: {x: 16, y: 16, path: 'M2 0C.903 0 0 .903 0 2v1.333c0 1.097.903 2 2 2h3.556c1.096 0 2-.903 2-2V2c0-1.097-.904-2-2-2H2Zm8.444 0c-1.096 0-2 .903-2 2v5.778c0 1.096.904 2 2 2H14c1.097 0 2-.904 2-2V2c0-1.097-.903-2-2-2h-3.556ZM2 1.333h3.556c.376 0 .666.29.666.667v1.333c0 .377-.29.667-.666.667H2a.657.657 0 0 1-.667-.667V2c0-.376.29-.667.667-.667Zm8.444 0H14c.376 0 .667.29.667.667v5.778c0 .376-.29.666-.667.666h-3.556a.657.657 0 0 1-.666-.666V2c0-.376.29-.667.666-.667ZM2 6.223c-1.097 0-2 .903-2 2V14c0 1.097.903 2 2 2h3.556c1.096 0 2-.903 2-2V8.222c0-1.096-.904-2-2-2H2Zm0 1.333h3.556c.376 0 .666.29.666.666V14c0 .376-.29.667-.666.667H2A.657.657 0 0 1 1.333 14V8.222c0-.376.29-.666.667-.666Zm8.444 3.11c-1.096 0-2 .904-2 2V14c0 1.097.904 2 2 2H14c1.097 0 2-.903 2-2v-1.333c0-1.097-.903-2-2-2h-3.556Zm0 1.334H14c.376 0 .667.29.667.667V14c0 .376-.29.667-.667.667h-3.556A.657.657 0 0 1 9.778 14v-1.333c0-.377.29-.667.666-.667Z'},
    info: {x: 16, y: 17, path: 'M2.444 1A2.455 2.455 0 0 0 0 3.444v11.112A2.455 2.455 0 0 0 2.444 17h11.112A2.455 2.455 0 0 0 16 14.556V3.444A2.455 2.455 0 0 0 13.556 1H2.444Zm0 1.333h11.112c.621 0 1.11.49 1.11 1.111v11.112c0 .621-.489 1.11-1.11 1.11H2.444c-.621 0-1.11-.489-1.11-1.11V3.444c0-.621.489-1.11 1.11-1.11ZM8 4.556a.889.889 0 1 0 0 1.777.889.889 0 0 0 0-1.777Zm-.01 3.101a.667.667 0 0 0-.657.676v4.89a.667.667 0 1 0 1.334 0v-4.89a.667.667 0 0 0-.677-.676Z'},
    scanlist: {x: 16, y: 15, path: 'M2.316 0a2.348 2.348 0 0 0-.686.103C.904.327.33.895.104 1.613A2.275 2.275 0 0 0 0 2.292v10.416C0 13.967 1.044 15 2.316 15h11.368C14.956 15 16 13.967 16 12.708V2.292a2.25 2.25 0 0 0-.104-.679 2.314 2.314 0 0 0-1.976-1.6A2.322 2.322 0 0 0 13.683 0H2.316Zm0 1.25c.348 0 .631.28.631.625a.629.629 0 0 1-.631.625.629.629 0 0 1-.632-.625c0-.345.283-.625.632-.625Zm2.105 0c.349 0 .632.28.632.625a.629.629 0 0 1-.632.625.629.629 0 0 1-.632-.625c0-.345.283-.625.632-.625Zm-3.158 2.5h13.474v8.958c0 .583-.464 1.042-1.053 1.042H2.316c-.589 0-1.053-.46-1.053-1.042V3.75ZM12.83 5.411a.635.635 0 0 0-.434.189l-1.238 1.225-.396-.392a.636.636 0 0 0-1.067.275.62.62 0 0 0 .174.609l.842.833a.636.636 0 0 0 .893 0l1.685-1.666a.62.62 0 0 0 .14-.692.632.632 0 0 0-.6-.381Zm-9.672.839a.633.633 0 0 0-.555.31.62.62 0 0 0 0 .63.633.633 0 0 0 .555.31H8.21a.633.633 0 0 0 .554-.31.62.62 0 0 0 0-.63.633.633 0 0 0-.554-.31H3.158Zm9.672 2.703a.635.635 0 0 0-.434.188l-1.238 1.225-.396-.391a.636.636 0 0 0-1.067.275.62.62 0 0 0 .174.609l.842.833a.636.636 0 0 0 .893 0l1.685-1.667a.62.62 0 0 0 .14-.691.632.632 0 0 0-.6-.381ZM3.158 10a.633.633 0 0 0-.555.31.62.62 0 0 0 0 .63.633.633 0 0 0 .555.31H8.21a.633.633 0 0 0 .554-.31.62.62 0 0 0 0-.63.633.633 0 0 0-.554-.31H3.158Z'},
    chapter: {x: 16, y: 16, path: 'M2.444 0A2.455 2.455 0 0 0 0 2.444v11.112A2.455 2.455 0 0 0 2.444 16h11.112A2.455 2.455 0 0 0 16 13.556V2.444A2.455 2.455 0 0 0 13.556 0H2.444Zm0 1.333h11.112c.621 0 1.11.49 1.11 1.111v11.112c0 .621-.489 1.11-1.11 1.11H2.444c-.621 0-1.11-.489-1.11-1.11V2.444c0-.621.489-1.11 1.11-1.11ZM7.99 3.101a.667.667 0 0 0-.657.677v8.444a.667.667 0 1 0 1.334 0V3.778A.667.667 0 0 0 7.99 3.1ZM4.212 5.768a.667.667 0 0 0-.656.676v5.778a.667.667 0 1 0 1.333 0V6.444a.667.667 0 0 0-.677-.676Zm7.555 2.667a.667.667 0 0 0-.656.676v3.111a.667.667 0 1 0 1.333 0v-3.11a.667.667 0 0 0-.677-.677Z'},
    contact: {x: 16, y: 17, path: 'M4.6 1a2.6 2.6 0 0 0-2.192 1.204c.064-.004.128-.004.192-.004h9.2c1.656 0 3 1.344 3 3v6.4c0 .063 0 .127-.004.191A2.6 2.6 0 0 0 16 9.6V5.2C16 2.885 14.116 1 11.8 1H4.6Zm-2 2A2.61 2.61 0 0 0 0 5.6v6a2.61 2.61 0 0 0 2.6 2.599h.2v1.814c0 .78.957 1.269 1.588.81l3.607-2.624H11.4a2.61 2.61 0 0 0 2.6-2.6v-6A2.61 2.61 0 0 0 11.4 3H2.6Zm0 1.2h8.8c.78 0 1.4.62 1.4 1.4v6c0 .78-.62 1.4-1.4 1.4H7.8a.6.6 0 0 0-.353.114L4 15.621v-2.022a.6.6 0 0 0-.6-.6h-.8c-.78 0-1.4-.62-1.4-1.4v-6a1.39 1.39 0 0 1 1.4-1.4Z'},
    circleCheck: {x: 13, y: 13, path: 'M6.5 0C2.919 0 0 2.919 0 6.5S2.919 13 6.5 13 13 10.081 13 6.5 10.081 0 6.5 0Zm0 1.5c2.77 0 5 2.23 5 5s-2.23 5-5 5-5-2.23-5-5 2.23-5 5-5Zm2.485 2.493a.75.75 0 0 0-.515.227L5.5 7.19 4.28 5.97a.75.75 0 1 0-1.06 1.06l1.75 1.75a.75.75 0 0 0 1.06 0l3.5-3.5a.75.75 0 0 0-.545-1.287Z'},
    videoPlayer: {x: 24, y: 22, path: 'M21 0a3 3 0 013 3v16a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 2H3a1 1 0 00-1 1v16a1 1 0 001 1h18a1 1 0 001-1V3a1 1 0 00-1-1zM9 5.131L17.803 11 9 16.869V5.13zm2 3.738v4.261L14.196 11 11 8.869z'},
    checkList: {x: 23, y: 22, path: 'M7.96 15.279v6.68H1.04v-6.68h6.92zm-1.92 1.918H2.959v2.843H6.04v-2.843zm16.92.463v1.918H9.54V17.66h13.42zM7.363 7.27L8.85 8.482l-4.762 5.83-3.444-3.28 1.323-1.39 1.945 1.853 3.452-4.227zm15.595 2.77v1.92H9.541v-1.92h13.418zm-15-10v6.681H1.041V.041h6.918zM6.04 1.96H2.959v2.843H6.04V1.959zm16.92.463V4.34H9.54V2.422h13.42z'},
    check: {x: 21, y: 16, path: 'M6.67 12.625L1.69 7.65 0 9.34 6.67 16 21 1.688 19.31 0z'},
    pencil: {x: 19, y: 19, path: 'M13.77.897a3.064 3.064 0 114.332 4.335L6.893 16.392l-.246.167-5.541 2.375c-.657.281-1.321-.383-1.04-1.04l2.375-5.54.167-.247zm-1.122 3.37l-8.813 8.85L2.3 16.698l3.586-1.537 8.848-8.809-2.085-2.085zm4.335-2.25a1.48 1.48 0 00-2.093-.001l-1.126 1.129 2.09 2.09 1.13-1.124a1.481 1.481 0 00.214-1.823l-.082-.12z'},
    questionCircle: {x: 15, y: 15, path: 'M7.5 0C3.364 0 0 3.364 0 7.5S3.364 15 7.5 15 15 11.636 15 7.5 11.636 0 7.5 0zm-.313 11.875a.938.938 0 11.001-1.876.938.938 0 010 1.876zm2.695-5.546c-.193.31-.56.657-1.101 1.043-.876.648-.852.792-.852 1.378H6.393c0-.458-.01-.81.238-1.238.158-.274.448-.566.869-.875.506-.361.997-.71.997-1.316 0-.568-.486-.77-1.054-.77-.58 0-1.24.189-1.982.568L4.829 3.85c1.348-.756 3.475-1.098 4.638-.15.854.695.86 1.918.415 2.63z'},
    chevronDown: {x: 25, y: 25, path: 'M21.61,7.35,12.5,14.64,3.38,7.35A2.08,2.08,0,1,0,.78,10.6L11.2,18.94a2.1,2.1,0,0,0,2.6,0L24.22,10.6a2.08,2.08,0,0,0-2.61-3.25Z'},
    chevronCircleLeft: {x: 20, y: 20, path: 'M11.25 15c-.384 0-.768-.146-1.061-.439l-3.5-3.5a1.5 1.5 0 010-2.121l3.5-3.5a1.5 1.5 0 112.121 2.121L9.871 10l2.439 2.439A1.5 1.5 0 0111.25 15z'},
    plusMinus: {x: 39, y: 23, path: 'M13.228 22.5v-9.272H22.5V9.207h-9.272V0h-4v9.207H0v4.02h9.229V22.5h3.999zm24.879-9.283v-4.01H26v4.02l12.107-.01z'},
    plus: {x: 23, y: 23, path: 'M13.45 22.913v-9.272h9.273V9.62h-9.272V.413h-4V9.62H.224v4.02h9.229v9.273z'},
    minus: {x: 13, y: 5, path: 'M12.56 4.102V.322H.453v3.78z'},
    plantGround: {x: 21, y: 20, path: 'M10.975.25a.75.75 0 01.743.648l.007.102-.001 6.75a4.849 4.849 0 013.797-1.816h1.429v3.403c0 2.615-2.162 4.729-4.821 4.729l-.405-.001.001 1.4c1.253.248 2.244 1.269 2.574 2.58l.045.204L20 18.25a.75.75 0 01.743.648l.007.102a.75.75 0 01-.648.743L20 19.75h-7.083V19c0-1.173-.82-2.092-1.784-2.092-.66 0-1.269.435-1.577 1.125l-.062.151-.395 1.087-.83-.804a.435.435 0 00-.302-.138c-.239 0-.467.229-.51.558L7.45 19v.75H1a.75.75 0 01-.102-1.493L1 18.25l5.074-.001.045-.12c.29-.715.928-1.236 1.701-1.295l.147-.005c.112 0 .223.01.331.03l.174.04.05-.073a3.288 3.288 0 011.702-1.279v-3.376h-.403c-2.587 0-4.704-2-4.816-4.518L5 7.443V4.038h1.429c1.54 0 2.913.71 3.797 1.815L10.225 1a.75.75 0 01.75-.75zm4.475 7.186l-.117.003c-1.75.096-3.133 1.506-3.133 3.224v1.9l.117-.002c1.688-.092 3.034-1.407 3.128-3.041l.005-.183V7.436zM6.499 5.541L6.5 7.442c0 1.78 1.483 3.23 3.321 3.23l-.072-.003.001-1.9c0-1.719-1.383-3.13-3.133-3.224L6.5 5.54z'},
    usersWw: {x: 21, y: 19, path: 'M7.486 1.001l.188.004.2.015c2.461.245 4.35 2.253 4.397 4.7v.164a6.057 6.057 0 001.23 4.053l.357.468-.428.407c-.478.455-1.115.759-1.869.946a7.868 7.868 0 01-1.558.216h-.017l.172 1.171 3.358.94A2.007 2.007 0 0115 16.006v2.994H0v-2.994c0-.893.606-1.678 1.484-1.923l3.358-.939.171-1.17-.269-.015a7.904 7.904 0 01-1.043-.142l-.262-.06c-.754-.187-1.391-.49-1.87-.946l-.425-.405.352-.467a6.247 6.247 0 001.231-4.272c0-1.27.53-2.485 1.466-3.365a4.827 4.827 0 013.089-1.297l.204-.005zm6.514-1l.191.004c2.424.171 4.296 2.263 4.26 4.794a6.177 6.177 0 001.022 3.8l.128.183.332.46-.4.4c-.445.448-1.041.746-1.745.93-.408.107-.821.17-1.219.199l-.252.014.16 1.15 3.137.923c.783.23 1.334.944 1.383 1.771l.003.12V19h-5.094v-1.31h3.82v-2.943a.656.656 0 00-.39-.603l-.072-.027-3.135-.922a1.295 1.295 0 01-.894-.974l-.018-.1-.377-2.711.87.065a6.153 6.153 0 001.765-.174c.224-.059.428-.131.608-.216l.136-.07-.024-.04a7.49 7.49 0 01-1.026-3.994l.01-.233c.025-1.804-1.326-3.313-3.057-3.436a3.097 3.097 0 00-1.744.454l-.141.092-.53.364L11 1.133l.53-.364A4.356 4.356 0 0114 .001zM7.46 2.335l-.17.006a3.446 3.446 0 00-2.152.923c-.669.629-1.047 1.497-1.05 2.352a7.5 7.5 0 01-1.09 4.52l-.023.037.148.072c.145.066.304.123.476.173l.176.048c.636.157 1.32.2 1.89.177l.93-.067-.403 2.758c-.076.521-.459.95-.975 1.094l-3.359.938a.669.669 0 00-.494.641v1.66h12.272v-1.66a.667.667 0 00-.417-.614l-.078-.027-3.357-.938a1.343 1.343 0 01-.976-1.094l-.403-2.758.846.062.303.01a6.808 6.808 0 001.671-.182c.3-.075.567-.171.798-.291l-.026-.04a7.327 7.327 0 01-1.099-4.065l.011-.237c.026-1.836-1.42-3.371-3.257-3.495l-.192-.003z'},
    cPulse: {x: 21, y: 21, path: 'M10.5.25c5.66 0 10.25 4.59 10.25 10.25S16.16 20.75 10.5 20.75.25 16.16.25 10.5 4.84.25 10.5.25zM8.637 7.863l-2.03 3.387H1.781a8.75 8.75 0 0017.436 0h-3.976l-3.15 5.25-3.455-8.637zM10.5 1.75a8.75 8.75 0 00-8.718 8h3.974L8.908 4.5l3.454 8.636 2.032-3.386h4.824a8.75 8.75 0 00-8.718-8z'},
    downloadArrow: {x: 13, y: 12, path: 'M4 3.5a.5.5 0 01.09.992L4 4.5H2a.5.5 0 00-.492.41L1.5 5v5a.5.5 0 00.41.492L2 10.5h9a.5.5 0 00.492-.41L11.5 10V5a.5.5 0 00-.41-.492L11 4.5H9a.5.5 0 01-.09-.992L9 3.5h2a1.5 1.5 0 011.493 1.356L12.5 5v5a1.5 1.5 0 01-1.356 1.493L11 11.5H2a1.5 1.5 0 01-1.493-1.356L.5 10V5a1.5 1.5 0 011.356-1.493L2 3.5h2zm2.5-3a.5.5 0 01.492.41L7 1v6.793l2.146-2.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-3 3-.013.011a.503.503 0 01-.039.033l.052-.044A.502.502 0 016.5 9.5h-.02a.503.503 0 01-.052-.005L6.5 9.5a.502.502 0 01-.284-.089l-.018-.013a.503.503 0 01-.04-.033l-.012-.011-3-3a.5.5 0 01.638-.765l.07.057L6 7.793V1a.5.5 0 01.5-.5z'},
    hook: {x: 21, y: 21, path: 'M10.4.5v10.1h10.1v1.8H8.6V.5z'},
    times: {x: 17, y: 16, path: 'M13.578.524a1.92 1.92 0 012.586 2.834L11.52 8l4.643 4.642a1.92 1.92 0 01-2.586 2.834l-.13-.118-4.642-4.643-4.642 4.643-.13.118a1.92 1.92 0 01-2.585-2.834L6.09 8 1.45 3.358A1.92 1.92 0 014.035.524l.129.118 4.642 4.643L13.45.642z'},
    search: {x: 17, y: 18, path: 'M6.78005865,0 C3.03170841,0 0,3.13646152 0,7.01432665 C0,10.8921922 3.03170841,14.0286533 6.78005865,14.0286533 C8.11831022,14.0286533 9.35685504,13.6224928 10.4068915,12.9326648 L15.3049853,18 L17,16.2464183 L12.1642229,11.256447 C13.0335413,10.0766476 13.5601173,8.61479216 13.5601173,7.01432665 C13.5601173,3.13646152 10.5284093,0 6.78005865,0 Z M6.88095238,1.63636364 C9.79492198,1.63636364 12.1428571,4.00941041 12.1428571,6.95454545 C12.1428571,9.8996805 9.79492198,12.2727273 6.88095238,12.2727273 C3.96698279,12.2727273 1.61904762,9.8996805 1.61904762,6.95454545 C1.61904762,4.00941041 3.96698279,1.63636364 6.88095238,1.63636364 Z'},
    angleDown: {x: 12, y: 8, path: 'M1.80248963 0L0 1.8481175 5.99999968 8 12 1.8481175 10.1975104 0 5.99999968 4.30376501z'},
    knowledgeBase: {x: 24, y: 22, path: 'M4.2,0 C3.21952464,0 2.4,0.834701022 2.4,1.83333333 L2.4,17.1111111 L0,17.1111111 L0,19.5555556 L2.4,19.5555556 L2.4,22 L4.8,22 L4.8,19.5555556 L7.8,19.5555556 L19.2,19.5555556 L19.2,22 L21.6,22 L21.6,19.5555556 L24,19.5555556 L24,17.1111111 L21.7734372,17.1111111 C21.7931916,16.7778576 21.7222344,16.488153 21.6609372,16.2469621 L18.9632808,5.14908851 L18.9609372,5.14192702 C18.8707512,4.78719218 18.842868,4.31098837 18.2414064,3.86002601 C17.9406756,3.63454496 17.557086,3.55203273 17.2875,3.55208333 C17.017914,3.55213393 16.8177288,3.59838368 16.6125,3.64995657 L14.4,4.19661464 L14.4,1.83333333 C14.4,0.834701022 13.5804756,0 12.6,0 L9,0 C8.78926476,0 8.58932592,0.0441243 8.4,0.114583333 C8.21067408,0.0441243 8.01073524,0 7.8,0 L4.2,0 Z M4.8,2.44444444 L7.2,2.44444444 L7.2,17.1111111 L4.8,17.1111111 L4.8,2.44444444 Z M9.6,2.44444444 L12,2.44444444 L12,17.1111111 L9.6,17.1111111 L9.6,2.44444444 Z M16.7320308,6.13736982 L19.2468744,16.4880638 L16.8,17.1111111 L16.7906256,17.1111111 L14.4093744,6.71028649 L16.7320308,6.13736982 Z'},
    twitter: {x: 28, y: 26, path: 'M13.0508475,0 C5.84351695,0 0,5.82075 0,13 C0,20.17925 5.84351695,26 13.0508475,26 C20.258178,26 26.1016949,20.17925 26.1016949,13 C26.1016949,5.82075 20.258178,0 13.0508475,0 Z M20.080904,10.3295833 C20.0874294,10.4736667 20.0906921,10.6166667 20.0906921,10.7596667 C20.0906921,15.1666667 16.724661,20.2453333 10.5679237,20.2453333 C8.67663842,20.2453333 6.91803672,19.6939167 5.43785311,18.7470833 C5.69995763,18.7785 5.96641243,18.7925833 6.23721751,18.7925833 C7.80658192,18.7925833 9.24870056,18.2606667 10.3928249,17.36475 C8.92786723,17.3376667 7.69129944,16.3735 7.26605932,15.0496667 C7.4705226,15.0886667 7.68042373,15.10925 7.89576271,15.10925 C8.20137006,15.10925 8.49827684,15.0680833 8.77778249,14.99225 C7.24648305,14.68675 6.09257062,13.3390833 6.09257062,11.7238333 C6.09257062,11.70975 6.09257062,11.6956667 6.09257062,11.6815833 C6.54391243,11.9318333 7.0594209,12.0813333 7.60864407,12.0986667 C6.71139831,11.50175 6.11975989,10.4801667 6.11975989,9.32425 C6.11975989,8.71216667 6.28398305,8.13908333 6.57218927,7.64833333 C8.22312147,9.6655 10.6897316,10.9936667 13.4706497,11.1323333 C13.414096,10.8885833 13.3847316,10.634 13.3847316,10.3729167 C13.3847316,8.53233333 14.883404,7.03841667 16.7311864,7.03841667 C17.6936864,7.03841667 18.5637429,7.44358333 19.1738701,8.09141667 C19.9362571,7.94083333 20.6529661,7.66458333 21.3000706,7.28216667 C21.0499294,8.06108333 20.5191949,8.71325 19.8275,9.12708333 C20.5039689,9.04691667 21.1488983,8.86816667 21.7503249,8.60275 C21.3033333,9.27225 20.736709,9.85833333 20.080904,10.3295833 Z'},
    linkedin: {x: 28, y: 27, path: 'M13.8,0C6.4,0,0.3,6,0.3,13.5S6.4,27,13.8,27s13.5-6,13.5-13.5S21.3,0,13.8,0z M8.8,6.1\tc0.9,0,1.6,0.6,1.6,1.5S9.7,9,8.7,9C7.7,9,7.1,8.4,7.1,7.6S7.7,6.1,8.8,6.1z M10.5,19.1H7.1v-9h3.4V19.1z M21.7,19.1h-3.2v-4.9\tc0-1.4-0.8-1.7-1.2-1.7S16,12.7,16,14.2c0,0.2,0,4.9,0,4.9h-3.3v-9H16v1.3c0.4-0.7,1.3-1.3,2.9-1.3c1.6,0,2.9,1.3,2.9,4.1V19.1z'},
    mail: {x: 17, y: 17, path: 'M8.5,0.2c-4.6,0-8.3,3.7-8.3,8.3c0,4.6,3.7,8.3,8.3,8.3c1,0,2-0.2,2.9-0.5c0.4-0.2,0.6-0.6,0.5-1.1\tc-0.2-0.4-0.6-0.7-1.1-0.5c-0.8,0.3-1.5,0.4-2.3,0.4c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7s6.7,3,6.7,6.7v0.8c0,0.9-0.7,1.7-1.7,1.7\ts-1.7-0.7-1.7-1.7V5.2c0-0.5-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.3-0.8,0.7C9.6,4.6,8.9,4.3,8.1,4.3C6,4.3,4.3,6.2,4.3,8.5\tc0,2.3,1.7,4.2,3.8,4.2c1.1,0,2.1-0.5,2.7-1.4c0.6,0.8,1.6,1.4,2.7,1.4c1.8,0,3.3-1.5,3.3-3.3V8.5C16.8,3.9,13.1,0.2,8.5,0.2z M8.1,11C6.9,11,6,9.9,6,8.5S6.9,6,8.1,6s2.1,1.1,2.1,2.5S9.2,11,8.1,11z'},
    results: {x: 17, y: 17, path: 'M2.316 0a2.348 2.348 0 0 0-.686.103C.904.327.33.895.104 1.613A2.275 2.275 0 0 0 0 2.292v10.416C0 13.967 1.044 15 2.316 15h11.368C14.956 15 16 13.967 16 12.708V2.292a2.25 2.25 0 0 0-.104-.679 2.314 2.314 0 0 0-1.976-1.6A2.322 2.322 0 0 0 13.683 0H2.316Zm0 1.25c.348 0 .631.28.631.625a.629.629 0 0 1-.631.625.629.629 0 0 1-.632-.625c0-.345.283-.625.632-.625Zm2.105 0c.349 0 .632.28.632.625a.629.629 0 0 1-.632.625.629.629 0 0 1-.632-.625c0-.345.283-.625.632-.625Zm-3.158 2.5h13.474v8.958c0 .583-.464 1.042-1.053 1.042H2.316c-.589 0-1.053-.46-1.053-1.042V3.75Zm8.886 1.664c-.337.01-.657.149-.892.388l-2.54 2.514-.813-.803a1.306 1.306 0 0 0-1.262-.353 1.29 1.29 0 0 0-.928.918c-.119.45.018.927.357 1.25l1.728 1.71c.507.5 1.328.5 1.834 0l3.458-3.422c.383-.369.497-.932.29-1.42a1.298 1.298 0 0 0-1.232-.782Z'},
});

export default defineComponent({
    name: 'Icon',
    props: {
        icon: {type: String as PropType<keyof typeof icons>, required: true}
    },
    computed: {
        path(): string {
            return icons[this.icon].path;
        },
        viewbox(): string {
            return `0 0 ${icons[this.icon].x} ${icons[this.icon].y}`;
        }
    },
})
