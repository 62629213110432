
import {defineComponent} from 'vue';
import {Role} from '@/data/schemas';
import useStorage from '@/composeables/useStorage';
import {useRouter} from 'vue-router';
import useParticipantRoles from '@/composeables/useParticipantRoles';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'SelectRole',
    components: {LoadingSpinner},
    setup() {
        const router = useRouter();

        return {
            storageRole: useStorage().getRole(),
            roles: useParticipantRoles().get(),
            roleSelected(role: Role) {
                useStorage().setRole(role).then((chapters) => {
                    if (chapters.length) {
                        router.push(chapters[0].getRoute(false));
                    }
                });
            }
        };
    }
})
