import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KnowledgebaseBlock = _resolveComponent("KnowledgebaseBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (item, j) => {
      return (_openBlock(), _createBlock(_component_KnowledgebaseBlock, {
        key: j,
        "share-text": _ctx.shareText,
        "knowledgebase-item": item
      }, null, 8, ["share-text", "knowledgebase-item"]))
    }), 128))
  ]))
}