
import {defineComponent, ref} from 'vue';
import api from '@/data/api';
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import {Routes} from '@/router';
import Icon from '@/components/Icon.vue';
import {KnowledgebaseItem} from '@/data/schemas';

export default defineComponent({
    name: 'KnowledgebaseDetail',
    components: {KnowledgebaseBlock, Icon},
    props: {
        slug: {type: String, required: true}
    },
    setup(props) {
        const knowledgebaseItem = ref<KnowledgebaseItem>();
        const relatedItems = ref<KnowledgebaseItem[]>();
        const totalRelatedItems = ref(-1);
        const shareText = ref('');

        if (props.slug) {
            api.getKnowledgeBaseItem(props.slug).then((res) => {
                knowledgebaseItem.value = res.data;
            });

            api.getKnowledgeBaseShareText().then((res) => {
                shareText.value = res.data;
            })
        }

        return {knowledgebaseItem, shareText, relatedItems, totalRelatedItems, Routes};
    }
})
