
import {defineComponent, ref} from 'vue';
import Icon from '@/components/Icon.vue';

export default defineComponent({
    name: 'DropdownBase',
    components: {Icon},
    props: {
        buttonText: {type: String, required: true},
        disabled: {type: Boolean, default: false},
        items: {type: Array, default: undefined}
    },
    emits: ['onSelect'],
    setup(props) {
        const isOpen = ref(false);
        const selectedItems = ref<number[]>([]);

        const open = () => {
            if (!props.disabled) {
                isOpen.value = true;
            }
        };

        const close = () => {
            isOpen.value = false;
        };

        return {isOpen, selectedItems, open, close};
    }
})
