
import {defineComponent, PropType} from 'vue';
import Icon from '@/components/Icon.vue';
import {KnowledgebaseItem} from '@/data/schemas';

export default defineComponent({
    name: 'KnowledgebaseBlock',
    components: {Icon},
    props: {
        knowledgebaseItem: {type: Object as PropType<KnowledgebaseItem>, required: true},
        shareText: {type: String, required: true}
    },
    setup() {
        return {
            getDetailUrl: (slug: string) => encodeURIComponent(`${window.location.origin}/#/kennisbank/${slug}`)
        };
    }
})
