import {Routes} from '@/router';
import {ChapterModel} from '@/models/ChapterModel';
import {GroupMenuItem} from '@/data/menu/GroupMenuItem';
import {SingleMenuItem} from '@/data/menu/SingleMenuItem';
import {Role} from '@/data/schemas';

export class ScanGroupMenuItem extends GroupMenuItem {
    constructor(label: string, chapters: ChapterModel[], answers: {[p: string]: number}, role: Role|undefined, startedScan: boolean, icon?: string) {
        const steps = [];

        // Basic logic for roles and scan status
        const introductionStep = new SingleMenuItem('Introductie', {name: Routes.ContentPageIntro});
        const selectRoleStep = new SingleMenuItem(role?.name || 'Kies Rol', {name: Routes.SelectRole}, 'usersWw');
        selectRoleStep.setAccessible(startedScan);
        if (chapters.length) {
            selectRoleStep.setSubLabel('Kies een andere rol');
        }

        let lastStep = (startedScan) ? selectRoleStep : introductionStep;

        steps.push(introductionStep);
        steps.push(selectRoleStep);

        // Chapter Menu Items Logic starts here. If no role is set, the "Chapters" array will be empty.
        let canAccessChapter = true;

        for (const i in chapters) {
            // Push this chapter into the steps array
            const menuItem = new SingleMenuItem(chapters[i].name, {name: Routes.ChapterQuestionList, params: {id: chapters[i].id.toString()}});
            steps.push(menuItem);

            // If this chapter is incomplete, then subsequent chapters are not accessible
            if (canAccessChapter) {
                lastStep = menuItem;
                canAccessChapter = chapters[i].isComplete(answers);
            }
        }

        super(label, lastStep.route, steps, icon);
    }
}