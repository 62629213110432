import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled", "onClick"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      disabled: _ctx.page <= 1,
      class: "prev-page",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPage(_ctx.page - 1)))
    }, " ← ", 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (i) => {
      return (_openBlock(), _createElementBlock("button", {
        key: i,
        disabled: _ctx.page === i,
        class: _normalizeClass({'current-page': _ctx.page === i}),
        onClick: ($event: any) => (_ctx.setPage(i))
      }, _toDisplayString(i), 11, _hoisted_3))
    }), 128)),
    _createElementVNode("button", {
      disabled: _ctx.page >= _ctx.totalPages,
      class: "next-page",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage(_ctx.page + 1)))
    }, " → ", 8, _hoisted_4)
  ]))
}