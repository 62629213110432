import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "list-title" }
const _hoisted_3 = {
  key: 1,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menuItem.isAccessible() ? 'router-link' : 'span'), {
    to: _ctx.menuItem.route,
    class: _normalizeClass(["menu-navigation-list__link", {'disabled': !_ctx.menuItem.isAccessible(), 'menu-option-role': _ctx.menuItem.subLabel}])
  }, {
    default: _withCtx(() => [
      (_ctx.menuItem.icon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_Icon, {
              icon: _ctx.menuItem.icon
            }, null, 8, ["icon"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.menuItem.label), 1),
      (_ctx.menuItem.subLabel)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.menuItem.subLabel), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}