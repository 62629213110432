
import {defineComponent, PropType, ref} from 'vue';
import ResultBlockKnowledgeItems from '@/components/resultBlocks/ResultBlockKnowledgeItems.vue';
import ChapterInfoBlock from '@/components/ChapterInfoBlock.vue';
import ChapterDescriptionStand from '@/components/ChapterDescriptionStand.vue';
import {ChapterModel} from '@/models/ChapterModel';
import api from '@/data/api';
import {KnowledgebaseItem} from '@/data/schemas';

type DataBlock = {
    readonly data: KnowledgebaseItem[];
    readonly title: string;
    readonly description: string;
};

export default defineComponent({
    name: 'ChapterKnowledgebaseList',
    components: {ChapterDescriptionStand, ChapterInfoBlock, ResultBlockKnowledgeItems},
    props: {
        chapter: {type: Object as PropType<ChapterModel>, required: true}
    },
    setup(props) {
        const selectedTab = ref(0);
        const contentBlocks = ref<{[key: number]: DataBlock}>({});
        const shareText = ref('');
        const resultText = ref();
        const contentRefs = ref<{[key: number]: HTMLElement}>([]);

        const setContentRef = (element: HTMLElement|null) => {
            if (element) {
                const id = element.dataset.contentBlockId;
                if (!id) {
                    throw 'Missing ID on Content Block.';
                }

                contentRefs.value[parseInt(id)] = element;
            }
        }

        const scrollTo = (index: number) => {
            contentRefs.value[index].scrollIntoView({block: 'start', behavior: 'smooth'});
        }

        const selectTab = (tab: number) => {
            selectedTab.value = tab;
            window.dispatchEvent(new Event('resize'));
        }

        api.getKnowledgeBaseItems({chapter: props.chapter.id.toString()}).then((response) => {
            for (const knowledgeBaseItem of response.data) {
                knowledgeBaseItem.knowledgeTypes.forEach((type) => {
                    if (type.id in contentBlocks.value) {
                        contentBlocks.value[type.id].data.push(knowledgeBaseItem);
                    } else {
                        contentBlocks.value[type.id] = {
                            data: [knowledgeBaseItem],
                            title: type.name,
                            description: type.description
                        };
                    }
                });
            }
        });

        api.getKnowledgeBaseShareText().then((response) => {
            shareText.value = encodeURIComponent(response.data);
        });

        return {selectedTab, contentBlocks, shareText, resultText, selectTab, scrollTo, setContentRef};
    }
});
