
import {defineComponent, ref} from 'vue';
import api from '@/data/api';
import useStorage from '@/composeables/useStorage';
import {Routes} from '@/router';
import {useRoute, useRouter} from 'vue-router';
import useModal from '@/composeables/useModal';
import useSchoolScan from '@/composeables/useSchoolScan';
import useLabels from '@/composeables/useLabels';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'Introduction',
    components: {LoadingSpinner},
    setup() {
        const content = ref('');
        const router = useRouter();
        const code = useRoute().query.code;

        if (typeof code === 'string' && code.length) {
            useSchoolScan().startSchoolScan(code, router);
        } else {
            api.getContentPage('introductie').then((response) => {
                content.value = response.data.content;
            });
        }

        return {
            content,
            startedScan: useStorage().getStartedScan(),
            schoolScan: useSchoolScan().getEntry(),
            labels: useLabels().get(),
            startIndividualScan() {
                useStorage().setStartedScan(true);
                router.push({name: Routes.SelectRole});
            },
            startSchoolScan() {
                useModal().open('StartSchoolScan', 'Organisatiescan invullen')
            },
            createSchoolScan() {
                useModal().open('CreateSchoolScan', 'Organisatiescan aanmaken')
            }
        };
    }
});
