import {useRoute} from 'vue-router';
import {MenuItemBase} from '@/data/menu/MenuItemBase';
import {SingleMenuItem} from '@/data/menu/SingleMenuItem';
import {RouterParams} from '@/data/schemas';

export class GroupMenuItem extends MenuItemBase {
    public readonly children;

    constructor(label: string, route: RouterParams, children: SingleMenuItem[], icon?: string) {
        super(label, route, icon);
        this.children = children;
    }

    public isActive() {
        if (GroupMenuItem.isRouteIdentical(this.route)) {
            return true;
        }

        for (const child of this.children) {
            if (GroupMenuItem.isRouteIdentical(child.route)) {
                return true;
            }
        }

        return false;
    }

    private static isRouteIdentical(route: RouterParams) {
        const currentRoute = useRoute();

        if (route.name !== currentRoute.name) {
            return false;
        }

        if (route.params) {
            const currentParams = currentRoute.params;
            for (const i in route.params) {
                if (currentParams[i] !== route.params[i]) {
                    return false;
                }
            }
        }

        return true;
    }
}