
import {defineComponent, PropType} from 'vue';
import Icon from '@/components/Icon.vue';
import {MenuItemBase} from '@/data/menu/MenuItemBase';

export default defineComponent({
    name: 'MenuItem',
    components: {Icon},
    props: {
        menuItem: {type: Object as PropType<MenuItemBase>, required: true}
    }
})
