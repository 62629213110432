
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import {Rating} from '@/data/enums';

export default defineComponent({
    name: 'RatingSymbol',
    components: {Icon},
    props: {
        rating: {type: Number, required: true}
    },
    setup() {
      return {Rating};
    },
})
