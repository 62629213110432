import {createApp} from 'vue';
import App from './App.vue'
import router from './router'
import mitt from 'mitt';
import VueGtag from 'vue-gtag-next';

const emitter = mitt();
const app = createApp(App);

declare module '@vue/runtime-core' {

}

// Global Properties
app.config.globalProperties.emitter = emitter;
app.use(router).use(VueGtag, {property: {id: 'GTM-TW3LGW6'}}).mount('#app');