
import Icon from '@/components/Icon.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'BaseModal',
    components: {Icon},
    props: {
        title: {type: String, required: true},
        show: {type: Boolean, required: true}
    },
    emits: ['onClose']
})
