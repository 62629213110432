
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import ChapterQuestion from '@/components/form/ChapterQuestion.vue';
import ResultProgressBar from '@/components/resultBlocks/ResultProgressBar.vue';
import useStorage from '@/composeables/useStorage';
import useChapters from '@/composeables/useChapters';
import useLabels from '@/composeables/useLabels';
import ChapterModalButton from '@/components/input/ChapterModalButton.vue';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'ResultSheet',
    components: {LoadingSpinner, ChapterModalButton, ResultProgressBar, ChapterQuestion, Icon},
    setup() {
        return {
            answers: useStorage().getAnswers(),
            chapters: useChapters().get(),
            labels: useLabels().get(),
            origin: window.location.origin
        };
    },
})
