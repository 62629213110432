
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'ResultProgressBar',
    props: {
        score: {type: Number, required: true},
        maxScore: {type: Number, required: true}
    },
    setup(props) {
        return {
            scorePercentage: (props.score / props.maxScore) * 100,
            prettyScore: computed(() => {
                if (props.score % 1 === 0) {
                    return props.score;
                }

                if (props.score % 0.1 === 0) {
                    return props.score.toFixed(1);
                }

                return props.score.toFixed(2);
            })
        };
    }
})
