import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "btn btn-primary mr-4 d-print-none",
  onclick: "window.print()"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}