
import {defineComponent, ref} from 'vue';
import {Statement} from '@/data/schemas';
import useStorage from '@/composeables/useStorage';

export default defineComponent({
    name: 'ChapterQuestion',
    props: {
        statement: {type: Object as () => Statement, required: true},
        label: {type: String, required: true}
    },
    setup(props) {
        const answers = useStorage().value('answers');
        const selectedAnswer = ref((props.statement.uuid in answers) ? answers[props.statement.uuid] : undefined);

        return {
            selectedAnswer,
            debugMode: window.debugMode
        }
    },
    watch: {
        selectedAnswer() {
            useStorage().setAnswer({
                uuid: this.statement.uuid,
                value: this.selectedAnswer
            });
        }
    },
})
