
import {computed, defineComponent, ref} from 'vue';
import SiteLogo from '@/components/SiteLogo.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import {SingleMenuItem} from '@/data/menu/SingleMenuItem';
import {ScanGroupMenuItem} from '@/data/menu/ScanGroupMenuItem';
import {ResultGroupMenuItem} from '@/data/menu/ResultGroupMenuItem';
import MenuItem from '@/views/layout/MenuItem.vue';
import useStorage from '@/composeables/useStorage';
import useSchoolScan from '@/composeables/useSchoolScan';
import useChapters from '@/composeables/useChapters';
import ClearCookieButton from '@/components/input/ClearCookieButton.vue';

export default defineComponent({
    name: 'HeaderMenu',
    components: {ClearCookieButton, MenuItem, TransitionExpand, SiteLogo},
    setup() {
        const chapters = useChapters().get();
        const hamburgerOpen = ref(false);

        const hamburgerToggle = (force?: boolean) => {
            hamburgerOpen.value = force ?? !hamburgerOpen.value;
            document.body.classList.toggle('drawer-open', hamburgerOpen.value);
        }

        return {
            startedScan: useStorage().getStartedScan(),
            chapters,
            hamburgerOpen,
            SingleMenuItem,
            hamburgerToggle,
            menuItems: computed(() => {
                let label = 'Doe de scan';
                const data = useSchoolScan().getEntry();

                if (data.value?.scan) {
                    label += ` ~ ${data.value.scan.schoolName}`;
                }

                return [
                    new SingleMenuItem('Home', {name: 'Home'}, 'home'),
                    new SingleMenuItem('Over deze scan', {name:'ContentPageAbout'}, 'info'),
                    new ScanGroupMenuItem(label, chapters.value ?? [], useStorage().value('answers'), useStorage().value('role'), useStorage().value('startedScan') ,'scanlist'),
                    new ResultGroupMenuItem('Resultaten', {name: 'ResultOverview'}, chapters.value ?? [], useStorage().value('answers'), 'results'),
                    new SingleMenuItem('Kennisbank', {name: 'KnowledgeBaseOverview'}, 'chapter')
                    // new SingleMenuItem('Taalonderdelen', {name: 'ContentPageCriteria'}, 'contact'),
                ];
            }),
            currentYear: new Date().getFullYear()
        };
    },
    watch: {
        $route() {
            this.hamburgerToggle(false);
        }
    }
})
