import {useRoute} from 'vue-router';
import {RouterParams} from '@/data/schemas';

export abstract class MenuItemBase {
    public readonly route: RouterParams;
    public readonly label: string;
    public readonly icon: string|undefined = undefined;
    public subLabel: string|undefined = undefined;
    protected accessible = true;

    constructor(label: string, route: RouterParams, icon?: string) {
        this.route = route;
        this.label = label;

        if (icon) {
            this.icon = icon;
        }
    }

    public setSubLabel(subLabel: string) {
        this.subLabel = subLabel;
    }

    public isActive() {
        return useRoute().name === this.route.name;
    }

    public isAccessible() {
        return this.accessible;
    }

    public setAccessible(value: boolean) {
        this.accessible = value;
    }
}