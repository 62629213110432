
import {defineComponent} from 'vue';
import Icon from '@/components/Icon.vue';
import ChapterResultNav from '@/components/ChapterResultNav.vue';
import useChapters from '@/composeables/useChapters';
import ChapterKnowledgebaseList from '@/components/ChapterKnowledgebaseList.vue';

export default defineComponent({
    name: 'ResultChapter',
    components: {ChapterKnowledgebaseList, ChapterResultNav, Icon},
    props: {
        id: {type: String, required: true}
    },
    setup(props) {
        return {
            chapter: useChapters().find(parseInt(props.id))
        }
    }
})
