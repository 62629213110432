
import {defineComponent, ref} from 'vue';
import api, {ApiResult} from '@/data/api';
import useModal from '@/composeables/useModal';

export default defineComponent({
    name: 'CreateSchoolScan',
    setup() {
        const isSubmitting = ref(false);
        const apiResult = ref<ApiResult>();

        return {
            isSubmitting,
            apiResult,
            onSubmit(event: Event & {target: HTMLFormElement}) {
                if (!isSubmitting.value) {
                    isSubmitting.value = true;

                    api.post('school-scan/scan', new FormData(event.target)).then((result) => {
                        apiResult.value = result;
                        if (result.ok) {
                            useModal().setTitle('Aanmaken organisatiescan geslaagd!');
                            isSubmitting.value = false;
                        }
                    }).catch((err) => {
                        console.error(err);
                    });
                }
            }
        };
    }
});
