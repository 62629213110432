
import {computed, defineComponent, ref} from 'vue';
import api from '@/data/api';
import KnowledgebaseBlock from '@/components/KnowledgebaseBlock.vue';
import Icon from '@/components/Icon.vue';
import DropdownBase from '@/components/form/DropdownBase.vue';
import Paginator from '@/components/form/Paginator.vue';
import {KnowledgebaseItem, KnowledgeRole, KnowledgeType, LanguageSubject} from '@/data/schemas';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

const ITEMS_PER_PAGE = 9;

export default defineComponent({
    name: 'KnowledgebaseOverview',
    components: {LoadingSpinner, Paginator, DropdownBase, KnowledgebaseBlock, Icon},
    setup() {
        const introTitle = ref('');
        const introText = ref('');
        const shareText = ref('');
        const knowledgeTypes = ref<KnowledgeType[]>();
        const languageSubjects = ref<LanguageSubject[]>();
        const knowledgebaseItems = ref<KnowledgebaseItem[]>();
        const knowledgeRoles = ref<KnowledgeRole[]>();
        const searchInput = ref('');

        const page = ref(1);

        const filterType = ref<number[]>([]);
        const filterLanguageSubject = ref<number[]>([]);
        const filterKnowledgeRoles = ref<number[]>([]);

        api.getKnowledgeBaseOverviewData().then((res) => {
            introTitle.value = res.data.title;
            introText.value = res.data.text;
            knowledgeTypes.value = res.data.knowledgeTypes;
            languageSubjects.value = res.data.languageSubjects;
            knowledgeRoles.value = res.data.knowledgeRoles;
            shareText.value = encodeURIComponent(res.data.shareText);
        });

        // Search logic
        let bouncer = 0;
        const onSearch = () => {
            if (bouncer) {
                clearTimeout(bouncer);
            }

            bouncer = setTimeout(() => {
                api.getKnowledgeBaseItems({
                    search: searchInput.value,
                    types: filterType.value.join(','),
                    languageSubjects: filterLanguageSubject.value.join(','),
                    knowledgeRoles: filterKnowledgeRoles.value.join(',')
                }).then((res) => {
                    knowledgebaseItems.value = res.data;
                });
            }, 250);
        };

        onSearch();

        return {
            knowledgebaseItems,
            shareText,
            knowledgeTypes,
            knowledgeRoles,
            languageSubjects,
            introTitle,
            introText,
            searchInput,
            filterType,
            filterLanguageSubject,
            filterKnowledgeRoles,
            page,
            paginatedKnowledgeBaseItems: computed(() => {
                const offset = (page.value - 1) * ITEMS_PER_PAGE;
                return knowledgebaseItems.value?.slice(offset, offset + ITEMS_PER_PAGE);
            }),
            onSearch
        };
    }
});
