import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "result-chapter-wrapper"
}
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = {
  key: 0,
  class: "content-field mb-4"
}
const _hoisted_4 = { class: "d-none d-lg-block" }
const _hoisted_5 = { class: "row pt-lg-3" }
const _hoisted_6 = { class: "d-block d-lg-none" }
const _hoisted_7 = { class: "content-tab" }
const _hoisted_8 = { class: "tab-navigation nav nav-pills nav-fill" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["data-content-block-id"]
const _hoisted_11 = {
  key: 0,
  class: ""
}
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "chapter-subject" }
const _hoisted_14 = { class: "chapter-subject__title d-flex flex-nowrap justify-content-between align-items-start mb-3" }
const _hoisted_15 = { class: "d-none d-lg-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterDescriptionStand = _resolveComponent("ChapterDescriptionStand")!
  const _component_ChapterInfoBlock = _resolveComponent("ChapterInfoBlock")!
  const _component_ResultBlockKnowledgeItems = _resolveComponent("ResultBlockKnowledgeItems")!

  return (_ctx.chapter && _ctx.contentBlocks)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.chapter.name), 1)
        ]),
        _createVNode(_component_ChapterDescriptionStand, { chapter: _ctx.chapter }, null, 8, ["chapter"]),
        (_ctx.resultText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.resultText), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentBlocks, (contentBlock, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                (contentBlock.data.length)
                  ? (_openBlock(), _createBlock(_component_ChapterInfoBlock, {
                      key: 0,
                      title: contentBlock.title,
                      onInfoBtn: ($event: any) => (_ctx.scrollTo(i))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(contentBlock.description), 1)
                      ]),
                      _: 2
                    }, 1032, ["title", "onInfoBtn"]))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("ul", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contentBlocks, (contentLink, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: i,
                  class: "nav-item"
                }, [
                  (contentLink.data.length)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: _normalizeClass(["nav-link cursor-pointer", {'selected-tab': _ctx.selectedTab === i}]),
                        onClick: ($event: any) => (_ctx.selectTab(i))
                      }, _toDisplayString(contentLink.title), 11, _hoisted_9))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ]),
        (_ctx.contentBlocks)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.contentBlocks, (contentSection, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                ref_for: true,
                ref: _ctx.setContentRef,
                "data-content-block-id": i,
                class: _normalizeClass(["d-block", {'d-none': _ctx.selectedTab !== i}])
              }, [
                (contentSection.data.length)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("h4", _hoisted_15, _toDisplayString(contentSection.title), 1)
                          ]),
                          _createElementVNode("div", null, [
                            _createVNode(_component_ResultBlockKnowledgeItems, {
                              content: contentSection.data,
                              "share-text": _ctx.shareText
                            }, null, 8, ["content", "share-text"])
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_10))
            }), 128))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}