
import {defineComponent} from 'vue';
import {useRouter} from 'vue-router';
import {Routes} from '@/router';
import useModal from '@/composeables/useModal';
import useSchoolScan from '@/composeables/useSchoolScan';
import useStorage from '@/composeables/useStorage';
import useChapters from '@/composeables/useChapters';

export default defineComponent({
    name: 'ResetStateButton',
    setup() {
        const router = useRouter();

        return {
            resetState() {
                useSchoolScan().clear();
                useStorage().clear();
                useChapters().clear();
                useModal().close();
                router.push({name: Routes.Home});
            }
        }
    }
})
