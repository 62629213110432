
import {defineComponent, PropType, ref} from 'vue';
import useLabels from '@/composeables/useLabels';
import {SchoolScanResultEntry} from '@/data/SchoolScanResultEntry';
import useParticipantRoles from '@/composeables/useParticipantRoles';
import ResultProgressBar from '@/components/resultBlocks/ResultProgressBar.vue';
import api from '@/data/api';
import {BundledResults, calculateAverageScore, countGivenAnswers} from '@/data/classes/BundledScoreCalculator';
import {ChapterModel} from '@/models/ChapterModel';
import {Role} from '@/data/schemas';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';

export default defineComponent({
    name: 'BundledResultsSheet',
    components: {LoadingSpinner, ResultProgressBar},
    props: {
        entries: {type: Array as PropType<SchoolScanResultEntry[]>, required: true}
    },
    setup(props) {
        const answerCount = ref<Record<string, number[]>>({});
        const bundledResults = ref<BundledResults>([]);
        const chapters = ref<Record<string, ChapterModel>>({});
        const roles = ref<Record<string, Role>>({});

        api.getChapters().then((_chapters) => {
            useParticipantRoles().value().then((_roles) => {
                for (const _chapter of Object.values(_chapters)) {
                    chapters.value[_chapter.id] = _chapter;
                }

                for (const _role of Object.values(_roles)) {
                    roles.value[_role.id] = _role;
                }

                bundledResults.value = calculateAverageScore(props.entries, _roles, _chapters);
            });

            answerCount.value = countGivenAnswers(props.entries);
        });

        return {
            chapters,
            roles,
            bundledResults,
            answerCount,
            labels: useLabels().get(),
            origin: window.location.origin
        };
    }
});
