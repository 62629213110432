import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "btn-rating outline-button-block outline-button-block--secondary" }
const _hoisted_2 = {
  key: 0,
  class: "plus"
}
const _hoisted_3 = {
  key: 1,
  class: "plusMinus"
}
const _hoisted_4 = {
  key: 2,
  class: "minus"
}
const _hoisted_5 = {
  key: 3,
  class: "plusMinus"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.rating === _ctx.Rating.Positive)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_Icon, { icon: "plus" })
        ]))
      : (_ctx.rating === _ctx.Rating.Neutral)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_Icon, { icon: "plusMinus" })
          ]))
        : (_ctx.rating === _ctx.Rating.Negative)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_Icon, { icon: "minus" })
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_5, " ?? "))
  ]))
}