import {ChapterModel} from '@/models/ChapterModel';
import {Routes} from '@/router';
import {SingleMenuItem} from '@/data/menu/SingleMenuItem';
import {GroupMenuItem} from '@/data/menu/GroupMenuItem';
import {RouterParams} from '@/data/schemas';

export class ResultGroupMenuItem extends GroupMenuItem {
    constructor(label: string, route: RouterParams, chapters: ChapterModel[], answers: {[p: string]: number}, icon?: string) {
        const steps = [];

        for (const i in chapters) {
            if (chapters[i].isComplete(answers)) {
                const menuItem = new SingleMenuItem(chapters[i].name, {name: Routes.ResultChapterAdvice, params: {id: chapters[i].id.toString()}}, 'hook');
                steps.push(menuItem);
            }
        }

        super(label, route, steps, icon);
        this.setAccessible(steps.length > 0);
    }
}