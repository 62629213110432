
import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'Paginator',
    props: {
        page: {type: Number, required: true},
        resultPerPage: {type: Number, required: true},
        totalResults: {type: Number, required: true}
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const setPage = (page: number) => {
            window.scrollTo({top: 0, behavior: 'smooth'});
            context.emit('update:modelValue', page);
        }

        return {
            setPage,
            totalPages: computed(() => {
                const totalPages = Math.max(Math.ceil(props.totalResults / props.resultPerPage), 1);

                if (props.page > totalPages) {
                    setPage(totalPages);
                }

                return totalPages;
            })
        }
    }
})
