import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chapter-result-navigation" }
const _hoisted_2 = { class: "chapter-result-navigation__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RatingSymbol = _resolveComponent("RatingSymbol")!
  const _component_ChapterNavItem = _resolveComponent("ChapterNavItem")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["chapter-result-navigation__inner d-flex flex-column w-100", {'show': _ctx.isOpen}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapters, (chapter, key) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
          (_ctx.isOpen || chapter.id === _ctx.currentId)
            ? (_openBlock(), _createBlock(_component_ChapterNavItem, {
                key: 0,
                to: `/resultaten/${chapter.id}`,
                class: _normalizeClass(["router-result-chapter", chapter.id === _ctx.currentId ? 'order-0' : 'order-1']),
                selected: chapter.id === _ctx.currentId,
                url: `/resultaten/${chapter.id}`,
                onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_RatingSymbol, {
                    rating: chapter.getRating(_ctx.answers)
                  }, null, 8, ["rating"]),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(chapter.name), 1)
                ]),
                _: 2
              }, 1032, ["to", "class", "selected", "url"]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ], 2),
    _createElementVNode("button", {
      class: _normalizeClass(["more-info-stand__button", {'show': _ctx.isOpen}]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isOpen = !_ctx.isOpen))
    }, [
      _createElementVNode("span", null, [
        _createVNode(_component_Icon, { icon: "chevronDown" })
      ])
    ], 2)
  ]))
}