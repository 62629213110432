import axios, {AxiosResponse} from 'axios';
import {ChapterModel} from '@/models/ChapterModel';
import {ChapterResultIntroText, CustomLabels, ContentPage, KnowledgebaseItem, KnowledgeBaseOverviewData, Role} from '@/data/schemas';

// Methods
export default {
    _fetch(endpoint: string, params: {[key: string]: string | undefined} = {}) {
        const origin = (window.location.origin.includes('localhost')) ? `http://${window.location.host}` : window.location.origin;
        const url = new URL(`${origin}/wp/api/${endpoint}/`);

        for (const key in params) {
            const param = params[key];
            if (param !== undefined) {
                url.searchParams.append(key, param);
            }
        }

        return axios.get(url.toString());
    },

    getRoles(): Promise<AxiosResponse<Role[]>> {
        return this._fetch('roles');
    },

    getChapters(roleId?: number): Promise<ChapterModel[]> {
        return this._fetch('chapters', {roleId: String(roleId)}).then((response) => {
            const models: ChapterModel[] = [];

            let i = 0;
            for (const chapterData of response.data) {
                models.push(new ChapterModel(chapterData, i));
                i++;
            }

            return models;
        });
    },

    getContentPage(slug: string): Promise<AxiosResponse<ContentPage>> {
        return this._fetch('content-page', {slug});
    },

    getKnowledgeBaseItems(params?: {[key: string]: string | undefined}): Promise<AxiosResponse<KnowledgebaseItem[]>> {
        return this._fetch('knowledge-base-items', params);
    },

    getKnowledgeBaseItem(slug: string): Promise<AxiosResponse<KnowledgebaseItem>> {
        return this._fetch('knowledge-base-detail', {slug});
    },

    getKnowledgeBaseOverviewData(): Promise<AxiosResponse<KnowledgeBaseOverviewData>> {
        return this._fetch('knowledge-base-overview-data');
    },

    getCustomLabels(): Promise<AxiosResponse<CustomLabels>> {
        return this._fetch('custom-labels');
    },

    getKnowledgeBaseShareText(): Promise<AxiosResponse<string>> {
        return this._fetch('knowledge-base-share-text');
    },

    getChapterResultIntroText(): Promise<AxiosResponse<ChapterResultIntroText>> {
        return this._fetch('chapter-result-intro-text');
    },

    async get(url: string, params: Record<string, string> = {}) {
        const searchParams = new URLSearchParams(params).toString();

        return fetch(`${window.location.origin}/wp/wp-json/${url}?${searchParams}`, {method: 'GET'}).then(async (response) => {
            return {
                ok: response.ok,
                statusText: response.statusText,
                status: response.status,
                data: await response.json() as unknown
            }
        });
    },

    async post(url: string, body: FormData) {
        return fetch(`${window.location.origin}/wp/wp-json/${url}`, {method: 'POST', body}).then(async (response) => {
            return {
                ok: response.ok,
                statusText: response.statusText,
                status: response.status,
                data: await response.json() as unknown
            }
        });
    }
};

export type ApiResult<T = unknown> = {
    ok: boolean,
    statusText: string,
    status: number,
    data: T
}
