
import api from '@/data/api';
import {defineComponent, ref} from 'vue';
import LoadingSpinner from '@/components/elements/LoadingSpinner.vue';
import {useRoute} from 'vue-router';

export default defineComponent({
    name: 'ContentPage',
    components: {LoadingSpinner},
    setup() {
        const content = ref('');
        const slug = useRoute().meta.slug;

        if (!slug) {
            throw 'No slug was defined for this ContentPage';
        }

        api.getContentPage(slug).then((response) => {
            content.value = response.data.content;
        });

        return {content};
    }
})
