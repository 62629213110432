
import {computed, defineComponent, ref} from 'vue';
import RatingSymbol from '@/components/RatingSymbol.vue';
import Icon from '@/components/Icon.vue';
import ChapterNavItem from '@/components/ChapterNavItem.vue';
import useStorage from '@/composeables/useStorage';
import useChapters from '@/composeables/useChapters';

export default defineComponent({
    name: 'ChapterResultNav',
    components: {ChapterNavItem, RatingSymbol, Icon},
    props: {
        currentId: {type: Number, required: true}
    },
    setup() {
        const isOpen = ref(false);

        return {
            answers: useStorage().getAnswers(),
            chapters: computed(() => useChapters().get().value?.filter((c) => !useStorage().getSkippedChapters().value.includes(c.id))),
            isOpen
        };
    },
    watch: {
        $route() {
            this.isOpen = false;
        },
    }
})
